// Remove the unnecessary import statement for NodeJS
import { breakpoints } from './media';
import { sharedAssetsPath } from './data';

export function setWindowElements() {
  window.ecs_vertical = document.body.getAttribute('data-section') || '';
  window.ecs_category = `expcom:consumer:${document.body.getAttribute('data-category')}`;
  // console.log('window.ecs_vertical: ' + window.ecs_vertical);
  // console.log('window.ecs_category: ' + window.ecs_category);
}
export function triggerAdobe() {
  if (window?.adobe?.target) {
    const el = document.getElementById('global_public_mbox') as HTMLElement | null;
    if (el) {
      el.innerHTML = '';
    }
    window.adobe.target?.getOffer({
      mbox: 'EXP_Global_Public_Framework',
      success: function (offer: any) {
        console.log('success in window.adobe.target.getOffer');
        window?.adobe.target.applyOffer({
          mbox: 'EXP_Global_Public_Framework',
          selector: el,
          offer: offer
        });
      },
      error: function (error: any) {
        console.error(error);
        if (el) {
          el.style.visibility = 'visible';
        }
      }
    });
  }
}
export const pluralize = (count: number, suffix = 's') => `${count !== 1 ? suffix : ''}`;
export const toNumber = (str: string) => {
  return Number(str);
};
export const addCommas = (num: number | string) => {
  // add separating commas to a number : 3000 => 3,000
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const removeCommas = (num: number | string) => {
  // remove separating commas from a number : 3,000 => 3000
  return num?.toString()?.replace(/,/g, '');
};
export const removeNonNumeric = (num: number | string) => {
  // remove non numeric characters: $5000 => 5000
  return num?.toString()?.replace(/[^0-9]/g, '');
};
export const ifObject = (data: object) => {
  // check if a variable is an Object
  return data && typeof data === 'object' && !Array.isArray(data);
};
export const scrollTo = (elementId: string, offset?: number) => {
  if (typeof window !== 'undefined') {
    // Will scroll smoothly to the top of the next section
    const element = document.getElementById(elementId);
    if (element && offset === undefined) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    if (element && offset !== undefined) {
      const y = element.getBoundingClientRect().top + window.scrollY + offset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
};
export const imageWithPrefix = (img: string) => {
  // adds prefix
  if (!img) return '';
  if (
    img.includes('https://') ||
    img.includes(sharedAssetsPath) ||
    (process.env.ASSETS_PATH && img.includes(process.env.ASSETS_PATH))
  ) {
    return img;
  }
  return `${process.env.ASSETS_PATH}${img}`;
};
export const removeExtraSpaces = (str: string) => {
  return str.replace(/\s+/g, ' ').trim();
};
export const ifDesktop = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth >= breakpoints.lg;
  }
  return false;
};
export const ifMobile = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < breakpoints.md;
  }
  return false;
};
export const ifMobileTablet = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < breakpoints.lg;
  }
  return false;
};
export const ifTablet = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < breakpoints.lg && window.innerWidth >= breakpoints.md;
  }
  return false;
};
export const ifXLDesktop = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth >= breakpoints.xl;
  }
  return false;
};
export const getWindowWidth = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }
  return 0;
};
export const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileRegex =
    /(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i;
  return mobileRegex.test(userAgent);
};
export function isAndroid(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return /android/i.test(userAgent);
}

export function isIOS(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return /(ipad|iphone)/i.test(userAgent);
}

export const addApostropheS = (str: string) => {
  // adds 's or just ' to the end of a string
  const t = str.slice(-1);
  return str + (t === 's' ? '‘' : '‘s');
};
export const getECSobjPropVal = (property: string) => {
  return window?.ECS?.tms?.data?.[property] ? window.ECS.tms.data[property] : '';
};
export const variableCheckPromise = (variable: any): Promise<any> => {
  // Set up a new interval to check every 100ms
  return new Promise((resolve, reject) => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count < 10) {
        if (variable) {
          clearInterval(intervalId);
          resolve(variable);
        }
      } else {
        clearInterval(intervalId);
        reject();
      }
      count++;
    }, 100);
  });
};
export const getCookie = (cname: string) => {
  const name = cname + '=';
  const cookies = document.cookie.split(';');
  let result = '';
  cookies.forEach((cookie) => {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.startsWith(name)) {
      result = trimmedCookie.substring(name.length);
    }
  });
  return result;
};
export function getQueryStringValue(key: string) {
  const urlParams = new URLSearchParams(window?.location?.search);
  return urlParams.get(key);
}
export function getQueryStringAsObject() {
  const params = new URLSearchParams(window?.location?.search);
  // Convert URLSearchParams to an object
  return params ? Object.fromEntries(params.entries()) : {};
}
export function isJson(str: any): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function useThrottle(func: any, delay: number = 500) {
  let timeout: any = null;
  return (...args: any[]) => {
    if (timeout) {
      return;
    }
    func(...args);
    timeout = setTimeout(() => {
      timeout = null;
    }, delay);
  };
}
export const loadScript = (src: string, id: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;

    script.onload = () => {
      resolve();
    };

    script.onerror = () => {
      reject(new Error(`Failed to load script: ${src}`));
    };

    document.head.appendChild(script);
  });
};
