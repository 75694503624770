import { imageWithPrefix } from './helpers';

export const breakpoints: Record<breakpointsNames, any> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};
export type breakpointsNames = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export const placeholderEmpty = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
export const placeholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP88ePnfwAJwwPq4n9NUAAAAABJRU5ErkJggg==';

export const placeholderDarker =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN89OT5fwAJCwOuncSt5QAAAABJRU5ErkJggg==';

export const placeholder30x19 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAATCAYAAACHrr18AAAAJUlEQVR42mP8/uN3PcMAAMZRi0ctHrV41OJRi0ctHrV41GK6AwAfeUDf9diU/gAAAABJRU5ErkJggg==';
export const placeholder18x13 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAYAAACkTj4ZAAAAGklEQVR42mP8/uN3PQMVAOOoQaMGjRpEF4MAOrcsY49QrVQAAAAASUVORK5CYII=';
export const getSocialImage = (image?: string) => {
  return imageWithPrefix(image || '/social/social-experian-generic.webp');
};
