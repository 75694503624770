export const appStoreAppLink =
  'https://control.kochava.com/v1/cpi/click?campaign_id=koecw-ios-live-u89f5gu6fdd1d73f6e2c&network_id=8710&site_id=2&device_id=device_id';
export const googlePlayAppLink =
  'https://control.kochava.com/v1/cpi/click?campaign_id=koecw-android-live-cwezmddp30f96b9db49bb9&network_id=8710&site_id=2&device_id=device_id';
export const gabiFunnelLink =
  'https://usa.experian.com/api/offer-redirect/crm/00000000-0000-2022-0202-114929961655/7754cb9f-82e2-48be-a6a4-a489f10b91fd';

export const trustpilotScriptUrl =
  '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
export const trustPilotWidgetUrl = 'https://www.trustpilot.com/review/experian.com';
export const trustpilotScriptId = 'trustPilotScript';
export const blogAPIEndpoint = 'https://www.experian.com/blogs/ask-experian/wp-json/exp';

export const sharedAssetsPath = '/static/shared';
export const sharedIconsPath = `${sharedAssetsPath}/icons`;
export const sharedLogosPath = `${sharedAssetsPath}/logos`;
export const sharedPatternsPath = `${sharedAssetsPath}/patterns`;
